<template>
  <div class='container'>
      <div class="search">
        <van-icon name="search" size="4.8vw"/>
        <input type="text" v-model="listQuery.name" placeholder="请输入标题进行查找">
        <button @click="search">搜索</button>
      </div>
    <div class="report-list" v-show="list.length>0">
      <div class="total">
        <p style="margin-left: 2.6667vw">共<b style="margin: 0 1.3333vw;">{{recordCount}}</b>名 学生预约咨询</p>
        <div style="display: flex;align-items: center;" @click="isShow=true">
          <img src="@/assets/project/screen.png" alt="" style="width: 2.6667vw;height: 2.6667vw;margin-right: 1.3333vw;">
          <p style="color: #666;font-size: 2.9333vw;;">筛选</p>
        </div>
      </div>
      <div class="list-content" v-for="item in list" :key="item.id">
        <div style="display: flex;align-items: center;justify-content: space-between;">
            <div style="display: flex;flex-direction: column;justify-content: space-around;flex: 1;">
              <p><b style="font-size: 4vw;margin-right: 2.6667vw;">{{item.name}}</b> <span style="background: #EFF9F8;padding: .8vw 2.1333vw;color:#10CA9B ;font-size: 2.9333vw;border-radius: .8vw;">{{transformGrade(item.grade)}} {{item.studyClass}}</span></p>
            </div>
            <!-- <p style="font-weight: 550;">一级预警</p> -->
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 4vw;">
          <div class="time">
            <img src="@/assets/project/time.png" alt="">
            <p style="font-size: 3.2vw;color: rgba(125, 125, 125, 1);">{{item.created_at}}</p>
          </div>
          <p style="padding: 1.0667vw 3.7333vw;background-color: #5EDBC9;color:#fff;border-radius: 1.3333vw;" @click="contact(item.phone)">联系TA</p>
        </div>
      </div>
      <div ref="bottom" style="position: relative; height: .2667vw;"></div>
    </div>
    <van-empty description="暂未学生预约咨询" v-show="list.length<=0"></van-empty>
    <van-popup v-model="isShow" position="right" :style="{ height: '100%',width:'80%' }" close-on-click-overlay>
      <div class="popup">
        <div style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 6.6667vw;">
          <p style="font-size: 4.2667vw;font-weight: 550;">列表筛选</p>
          <img src="@/assets/project/close.png" alt="" style="width: 6.4vw;height: 6.4vw;" @click="isShow=false">
        </div>
        <div class="popup-list">
          <div class="option" >
            <!-- <p>姓名</p> -->
            <input type="text" v-model="listQuery.name" placeholder="姓名">
          </div>
          <div class="option" >
            <p>手机号</p>
            <input type="text" v-model="listQuery.phone" placeholder="手机号">
          </div>
          <div class="option">
            <p>学籍号</p>
            <input type="text" v-model="listQuery.sn" placeholder="学籍号">
          </div>

        </div>
        <div class="btns">
          <button style="color: #47D5B2;" @click="reset">重置</button>
          <button @click="filterSearch">搜索</button>
        </div>
      </div>
    </van-popup>
    <van-dialog v-model="isDialog" :showConfirmButton="false">
      <div class="dialog">
        <p>请拨打学生联系电话</p>
        <p>{{dialogPhone}}</p>
        <button @click="isDialog=false">我知道了</button>
      </div>
    </van-dialog>
    <Tabbar :activeIndex="1"></Tabbar>
  </div>
</template>

<script>
import Tabbar from '@/components/Tabbar'
import {bottomLoading} from '@/utils/util.js'
import {exams} from '@/api/exam.js'
import { bookList } from '@/api/booking'
import {reportList,gradeAndStudyClass,} from '@/api/report.js'
export default {
  components: {
    Tabbar,
  },
  data(){
    return{
      activeIndex:1,
      searchValue:'',
      isShow:false,
      isPopup:false,
      isDialog:false,
      popupName:'',
      list:[],
      // 筛选栏目绑定数组
      listQuery: {
        pageNo: 1,
        pageSize: 20,
        name: '',
        sn: '',
        phone: ''
      },
      dialogPhone:'',
      totalPageCount:0,
      recordCount:0,
      gradeList:[],
    }
  },
  created(){
    this.getList()
    this.getGradeOrClass()
  },
  mounted(){
    bottomLoading(this.$refs.bottom,this.loadNextPage)
  },
  updated(){
  },
  methods:{
    // 获取列表
    async getList() {
      const listQuery = JSON.parse(JSON.stringify(this.listQuery))
      const formData = {}
      for (const el in listQuery) {
        if (listQuery[el] !== '') {
          formData[el] = listQuery[el]
        }
      }
      const res = await bookList(listQuery)
      res.data.list.forEach(ele => {
        for (const key in ele) {
          if (ele[key] === '') {
            ele[key] = '-'
          }
        }
      })
      this.list = res.data.list || []
      /* this.list.map(item => {
        if (item.created_at) {
          const newCreated = item.created_at.split(' ')
          item.created_at = newCreated[0] + ' ' + newCreated[1].split(':')[0] + ':' + newCreated[1].split(':')[1]
        }
      }) */
      this.recordCount = res.data.recordCount
      this.totalPageCount=res.data.totalPageCount
    },
    contact(phone){
      this.dialogPhone=phone
      this.isDialog=true
    },
    reset(){
      this.listQuery={
        pageNo: 1,
        pageSize: 10,
        name: '',
        sn: '',
        phone: ''
      },
      this.getList()
      this.isShow=false
    },
    search(){
      this.listQuery.pageNo=1
      this.getList()
      this.listQuery.title=''
    },
    filterSearch(){
      this.listQuery.pageNo=1
      this.getList()
      this.isShow=false
    },
    // 筛选确认
    onConfirm(value){
      this.isPopup=false
      console.log(value);
    },
    async getGradeOrClass(){
      const res = await gradeAndStudyClass()
      res.data.grades.unshift({grade:-1,gradeName:'全部'})
      this.gradeList=res.data.grades
    },
    transformGrade(grade){
      let gradeName=''
      this.gradeList.forEach(item => {
        if(item.grade===grade){
          // console.log(grade,item.gradeName);
          gradeName=item.gradeName
        }
      })
      return gradeName
    },
    async loadNextPage() {
      const formData={}
      if(this.listQuery.pageNo<this.totalPageCount){
        this.listQuery.pageNo++
        const res = await bookList(formData)
        this.totalPageCount=res.data.totalPageCount
        this.recordCount=res.data.recordCount
        this.list.push(...res.data.list)
      }
      // console.log('触发',this.listQuery.pageNo);
      // 在这里编写请求下一页数据的逻辑
      // 可以使用AJAX或其他方式向服务器发送请求
      // 将获取的数据添加到this.list数组中
      // 更新this.page变量为下一页的页码
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  padding: 4vw 3.2vw 13.3333vw 3.2vw;
  background-color: #fff;
  min-height: 100%;
  .search{
      // margin-top: 4vw;
      padding: 0 .5333vw 0 4vw;
      display: flex;
      height: 9.6vw;
      align-items: center;
      background: #F5F7F9;
      border-radius: 13.3333vw;
      input{
        padding: 0 4vw;
        flex: 1;
        height: 100%;
        background: #F5F7F9;
        &::placeholder{
          color: #666 ;
          // font-weight: 550;
        }
      }
      button{
        padding: 0 6.6667vw;
        height: 8.5333vw;
        line-height: 8vw;
        color: rgba(255, 255, 255, 1);
        border-radius: 13.3333vw;
        background-color: #5EDBC9 ;
      }
    }
  .total{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4vw 0;
    color: rgba(149, 149, 149, 1);
  }
  .project-list{
    .list-content{
      margin-bottom: 5.3333vw;
      padding: 4vw;
      border-radius: 2.6667vw;
      background-color: rgba(255, 255, 255, 1);
      .time{
        padding: 1.3333vw 2.6667vw;
        margin-right: 4vw;
        display: flex;
        align-items: center;
        color: #797979;
        font-size: 2.9333vw;
        background: #FEF9F3;
        border-radius: .8vw;
        img{
          width: 3.2vw;
          height: 3.2vw;
          margin-right: 2.1333vw;
        }
      }
      .message{
        font-size: 2.9333vw;
        font-weight: 550;
        .message-num{
          margin-bottom: 1.3333vw;
          width: 12.5333vw;
          height: 8vw;
          line-height: 8vw;
          border-radius: 1.3333vw;
          background-color: rgba(219, 219, 219, 1);
          text-align: center;
        }
      }
      .status{
        margin-top: 4vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button{
          padding: 1.3333vw 4vw;
          font-size: 3.2vw;
          border-radius: 1.3333vw;
          border: .2667vw solid rgb(0, 0, 0);
          background-color: #fff;
          margin-right: 1.6vw;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
  .report-list{
    .list-content{
      margin-bottom: 5.3333vw;
      padding: 4vw;
      border-radius: 2.6667vw;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0vw 0vw 2.6667vw 0vw rgba(187,187,187,0.15);
      .time{
        padding: 1.3333vw 2.6667vw;
        margin-right: 4vw;
        display: flex;
        align-items: center;
        color: #797979;
        font-size: 2.9333vw;
        background: #FEF9F3;
        border-radius: .8vw;
        img{
          width: 3.2vw;
          height: 3.2vw;
          margin-right: 2.1333vw;
        }
      }
      .status{
        padding: 1.3333vw 2%;
        display: flex;
        width: 13.8667vw;
        height: 13.8667vw;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        font-size: 3.7333vw;
        border-radius: 1.3333vw;
        background-color: rgba(219, 219, 219, 1);
        p{
          text-align: center;
        }
      }
    }
  }
  .popup{
    position: relative;
    padding: 5.8667vw 6.6667vw 13.8667vw 6.6667vw;
    .popup-list{
      height: 80vh;
      overflow: scroll;
      width: 100%;
      // margin-top: 6.6667vw;
      .option{
        display: flex;
        flex-direction: column;
        margin-bottom: 2.6667vw;
        color: #333;
        .selectInput{
          position: relative;
          input{
            padding-right: 9.3333vw;
            width: 100%;
          }
          .arrow{
            position: absolute;
            top: 53%;
            right:2.4vw;
            transform: translateY(-50%);
          }
        }
        input{
          margin-top:1.3333vw ;
          padding: 0 5.3333vw;
          height: 12.2667vw;
          background: #F5F7F9;
          border-radius: 13.3333vw;
          // border: .2667vw solid rgba(187, 187, 187, 1);
          &::placeholder{
            color: #666;
          }
        }
        p{
          font-weight: 550;
        }
      }
    }
    .btns{
      position: fixed;
      display: flex;
      align-items: center;
      padding: 0 6.6667vw;
      width: 100%;
      height: 16vw;
      left: 0;
      bottom: 0;
      button{
        flex: 1;
        font-size: 4.2667vw;
        color: #fff;
        height: 12.2667vw;
        line-height: 12.2667vw;
        background: #EFF9F8;
        border-radius: 13.3333vw;
        &:last-child{
          margin-left: 6.6667vw;
          background: #10CA9B;
        }
      }
    }
  }
  .dialog{
    padding-top: 4vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3.7333vw;
    p{
      font-size: 5.3333vw;
      line-height: 6.4vw;
      &:nth-of-type(2){
        margin-top: 4vw;
        font-size: 4vw;
      }
    }
    button{
      margin:4vw 0;
      width: 45.3333vw;
      height: 11.2vw;
      color: #fff;
      line-height: 11.2vw;
      text-align: center;
      border-radius: 13.3333vw;
      background-color: #5EDBC9;
    }
  }
}
</style>